import { useEffect, useRef } from "react";
import ChatMessages from "./Messages/messages";
import Header from "./Contents/Header";
import { useNavigate, useParams } from "react-router-dom";
import addChat from "../../utils/firestoreFunctions/addChat";
import { getChat } from "../../utils/firestoreFunctions/getChat";
import UserAuthStore from "../../store/userStore";
import { loadFirstChatWithMessageCount } from "../../utils/firestoreFunctions/loadChatMessages";
import ChatStore from "../../store/chatStore";

const MainContent = () => {
  const scrollRef = useRef(null);
  const chat_id = useParams().id;
  const { user } = UserAuthStore();

  const RemoveUpload = () => {};
  const navigate = useNavigate();
  const { setActiveChat } = ChatStore();

  useEffect(() => {
    const shouldCreateNewChat = async () => {
      if (chat_id === undefined && user?.uid) {
        const lastChat = await loadFirstChatWithMessageCount();
        if (lastChat === null || lastChat?.messageCount) {
          const ch_uid = await addChat({ RemoveUpload, getChat });
          setActiveChat(ch_uid);
          navigate(`/chat/${ch_uid}`);
        } else {
          setActiveChat(lastChat.chatId);
          navigate(`/chat/${lastChat.chatId}`);
        }
      }
    };
    shouldCreateNewChat();
  }, [user, chat_id]);

  return (
    <main className="flex-1 relative w-full">
      <Header />
      <div ref={scrollRef}>
        <div className="max-w-4xl min-w-80 mx-auto px-8 sm:px-4 xs:px-0 xm:px-0 mt-1 flex flex-col w-full h-[80%]">
          {chat_id ? <ChatMessages chat_id={chat_id} /> : <></>}
        </div>
      </div>
    </main>
  );
};

export default MainContent;
