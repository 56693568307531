import { collection, query, where, getDocs, updateDoc, setDoc, serverTimestamp, doc } from 'firebase/firestore';

export const isUserInFirestore = async (db, orgId, userId) => {
  const usersRef = collection(db, 'organisation', orgId, 'users');
  const q = query(usersRef, where('user_id', '==', userId));

  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
    return true;
  }

  return false;
};

export const createUserInFirestore = async (db, orgId, userData) => {
  const usersRef = collection(db, 'organisation', orgId, 'users');
  const userDocRef = doc(usersRef, userData.uid);

  await setDoc(userDocRef, {
    user_id: userData.uid,
    email: userData.email,
    name: userData.displayName || '',
    picture: userData.photoURL || '',
    access_token: userData.accessToken,
    refresh_token: userData.refreshToken,
    org_mode: false,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
  });
};


export const addUserInFirestore = async (db, orgId, userId, userData) => {
  const usersRef = collection(db, 'organisation', orgId, 'users');
  const userDocRef = doc(usersRef, userId);

  await setDoc(userDocRef, {
    user_id: userId,
    email: userData.email,
    name: userData.name || '',
    picture: userData.picture || '',
    org_mode: false,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
  });
};


