import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import addChat from "./addChat";
import UserAuthStore from "../../store/userStore";
import PromptStore from "../../store/promptStore";
import ChatStore from "../../store/chatStore";
import UIStore from "../../store/uiStore";
import { logout } from "../session/logout";
import EnvStore from "../../store/secretStore";


function convertData(doc) {
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
    timestamp: data.timestamp?.toDate().toString(),
    updated_at: data.updated_at?.toDate().toString(),
  };
}

export async function getChat(
  first,
  RemoveUpload
) {

  const { setPromptDisable } = PromptStore.getState()
  const { setActiveChat, setSideChat } = ChatStore.getState()
  const { user, orgId } = UserAuthStore.getState()
  const { modeRef } = UIStore.getState()

  if (!user) {
    logout()
    return
  }

  setPromptDisable(true);

  try {

    const chatsRef = collection(
      EnvStore.getState().db,
      "organisation",
      orgId,
      "users",
      user?.uid,
      "chats"
    );

    const chatsQuery = query(
      chatsRef,
      where("mode", "==", modeRef),
      orderBy("updated_at", "desc")
    );

    const querySnapshot = await getDocs(chatsQuery);
    const chats = querySnapshot.docs.map((doc) => convertData(doc));

    first = false
    if (chats?.length > 0) {
      // loadChatMessages(
      //   chats[0].uid,
      //   messageRef,
      //   scrollRef,
      //   addCopyButtons,
      //   first
      // );
      // setActiveChat(chats?.uid);
    } else {
      await addChat({
        RemoveUpload,
        getChat
      });
    }
    setSideChat(chats);
    setPromptDisable(false);
  } catch (error) {
    console.log("in get chat", error)
  }
}
