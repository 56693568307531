import { useRef, useState } from "react";
import {
  BlackCross,
  CrossSvg,
  LightOrgSearch,
  LightPdf,
  LightPdfUp,
  Orgsearch,
  Pdf,
  PdfUpload,
} from "../../../assets";
import { FirebaseUpload } from "../../../utils/firestoreFunctions/firebaseUpload";
import { FileStore } from "../../../store/fileUploadStore";
import { DeleteUploadedFile } from "../../../utils/firestoreFunctions/deleteFileFromDb";

export const DocumentDropZone = ({ theme }) => {
  return (
    <div className="flex dark:bg-dropShadow bg-white rounded-lg items-center justify-center w-[240px]">
      <div className="text-center">
        <div className="text-[#4FD1C5] mb-3 flex justify-center">
          {theme === "dark" ? (
            <Orgsearch className="h-10" />
          ) : (
            <LightOrgSearch className="h-10" />
          )}
        </div>
        <p className="dark:text-white text-[#0D3148] text-base font-semibold text-center">
          Drop your document
          <br />
          to search within it.
        </p>
      </div>
    </div>
  );
};

export const FileUploadZone = ({ theme, chat_id }) => {
  const fileInputRef = useRef(null);
  const files = FileStore((state) => state.files);
  const { fileLoading, progress } = FileStore();

  const formatFileSize = (bytes) => {
    const mb = (bytes / (1024 * 1024)).toFixed(1);
    return `${mb} MB`;
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    const file = e.target?.files?.[0];
    if (!file) {
      // alert("No file provided!");
      return;
    }
    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
    if (fileSizeMB > 10) {
      console.log("file size is greater than 10MB!"); // update to TOAST
      return;
    }

    const filePayload = {
      current: {
        files: [file],
      },
    };

    FirebaseUpload(chat_id, filePayload);
  };

  const removeFile = async (file) => {
    DeleteUploadedFile(file?.file__path, chat_id);
  };

  if (files?.length)
    return (
      <div className="flex-1 border-2 border-dashed dark:border-gray-700 border-[#9FABCA] rounded-lg p-2 relative w-[520px]">
        {files.map((file, index) => (
          <div
            key={index}
            className="w-full flex flex-col items-center justify-center relative"
          >
            <div
              onClick={() => removeFile(file)}
              className="absolute cursor-pointer top-[-2px] right-[0px]"
            >
              {theme === "dark" ? (
                <CrossSvg className="h-4" />
              ) : (
                <BlackCross className="h-4" />
              )}
            </div>
            {theme === "dark" ? (
              <Pdf className="h-14" />
            ) : (
              <LightPdfUp className="h-14" />
            )}
            <p key={index} className="truncate">{file.name}</p>
            <p>File Size: {formatFileSize(file.size)}</p>
            {console.log(fileLoading, progress)}
            {fileLoading && (
              <div className="mt-3 flex items-center justify-between w-3/4 mx-2">
                <div
                  id="progress-bar"
                  className="bg-white w-full rounded-2xl h-2"
                >
                  <div
                    className="bg-yellow-500 rounded-2xl h-2"
                    style={{
                      width: `${progress}%`,
                    }}
                  ></div>
                </div>
                <span className="text-gray-400 text-[10px] ml-2">
                  {progress}%
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    );

  return (
    <div className="flex-1 border-2 border-dashed dark:border-[#9FABCA] border-[#9FABCA] rounded-lg p-2 relative w-[520px] bg-[#FFF0EB] dark:bg-[#373745]">
      <div className="items-center flex flex-col justify-center">
        {theme === "dark" ? (
          <PdfUpload className="w-24 h-20 mx-auto text-gray-400" />
        ) : (
          <LightPdf className="w-24 h-20 mx-auto" />
        )}

        <label
          htmlFor="fileInput"
          className="text-sm mt-3 dark:text-textGray text-[#6D808D]"
        >
          <span className="dark:text-[#4FD1C5] text-[#FF5B29] hover:underline cursor-pointer font-semibold">
            Click to upload
          </span>{" "}
          or drag and drop
        </label>
        <span className="text-xs dark:text-[#CCD6F6] text-[#6D808D]">
          Maximum file size 10 MB.
        </span>
        <input
          type="file"
          id="fileInput"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
      </div>
    </div>
  );
};
