import React, { useEffect, useRef, useState } from "react";
import {
  Notebook,
  Setting,
  Plus,
  Dot,
  BlackPlus,
  BlackNotebook,
  BlackSetting,
  BlackDot,
} from "../../assets";
import UIStore from "../../store/uiStore";
import ChatStore from "../../store/chatStore";
import { updateChatTitle } from "../../utils/firestoreFunctions/updateChatTitle";
import { deleteChat } from "../../utils/firestoreFunctions/deleteChat";
import addChat from "../../utils/firestoreFunctions/addChat";
import { getChat } from "../../utils/firestoreFunctions/getChat";
import FileUploadStore from "../../store/fileUploadStore";
import { NavLink, useNavigate } from "react-router-dom";
import { useTheme } from "../../context/ThemeProvider";
import "../../assets/css/styles.css";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

const SidebarToggler1 = ({ showSidebar, toggleSidebar, theme }) => {
  return (
    !showSidebar && (
      <div
        className={`fixed top-4 left-4 z-[10001] h-10 w-10 cursor-pointer flex justify-center items-center rounded-full ${
          theme === "dark" ? "bg-[#424453]" : "bg-[#FFEBE6]"
        }`}
        onClick={toggleSidebar}
      >
        {theme === "dark" ? <Notebook /> : <BlackNotebook />}
      </div>
    )
  );
};

const SideBarHeader = ({
  theme,
  toggleSidebar,
  toggleTheme,
  isSettingsDropdownOpen,
  setIsSettingsDropdownOpen,
}) => {
  // Handle click outside the dropdown
  const closeDropdown = (e) => {
    if (!e.target.closest(".settings-dropdown")) {
      setIsSettingsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  const handleThemeToggle = (e) => {
    e.stopPropagation(); // Prevent click event from propagating
    toggleTheme(); // Call the theme toggle function
  };

  return (
    <div className="flex items-center justify-between px-3 mb-2 mt-4">
      <div
        onClick={toggleSidebar}
        className={`h-10 w-10 cursor-pointer flex justify-center items-center rounded-full ${
          theme === "dark" ? "bg-[#424453]" : "bg-[#FFEBE6]"
        }`}
      >
        {theme === "dark" ? (
          <Notebook onClick={toggleSidebar} />
        ) : (
          <BlackNotebook onClick={toggleSidebar} />
        )}
      </div>
      <div
        className={`relative h-10 w-10 cursor-pointer flex justify-center items-center rounded-full ${
          theme === "dark" ? "bg-[#424453]" : "bg-[#FFEBE6]"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setIsSettingsDropdownOpen((prev) => !prev);
        }}
      >
        {theme === "dark" ? <Setting /> : <BlackSetting />}
        {isSettingsDropdownOpen && (
          <div
            className={`absolute right-0 top-12 w-48 rounded-md shadow-lg z-50 settings-dropdown ${
              theme === "dark"
                ? "bg-[#252530] border-gray-700"
                : "bg-white border-gray-100"
            }`}
          >
            <div className="py-2 px-4 flex items-center justify-between">
              <span
                className={`text-sm ${
                  theme === "dark" ? "text-gray-300" : "text-gray-700"
                }`}
              >
                Toggle Theme
              </span>
              <div
                onClick={handleThemeToggle}
                className={`relative inline-flex items-center w-12 h-6 rounded-full cursor-pointer ${
                  theme === "dark" ? "bg-green-500" : "bg-gray-300"
                }`}
              >
                <div
                  className={`absolute flex items-center justify-center w-5 h-5 rounded-full transform transition-transform ${
                    theme === "dark"
                      ? "translate-x-6 bg-white"
                      : "translate-x-0 bg-gray-500"
                  }`}
                >
                  {theme === "dark" ? "🌙" : "☀️"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const NewChatButton = ({ theme, RemoveUpload }) => {
  const { addChatDisabled } = ChatStore();
  const navigate = useNavigate();
  return (
    <div
      onClick={
        addChatDisabled
          ? undefined
          : async () => {
              const chat_id = await addChat({
                RemoveUpload,
                getChat,
              });
              if (chat_id) {
                navigate(`/chat/${chat_id}`);
              }
            }
      }
      className={`p-2 h-[38px] rounded-md mt-3 flex items-center ${
        theme === "dark"
          ? "bg-[#444858] text-white hover:opacity-[60%] opacity-[100%]"
          : "bg-[#FFEAE6] hover:opacity-[60%] opacity-[100%] text-black"
      } ${addChatDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
    >
      {theme === "dark" ? (
        <Plus className="mr-1 h-4" />
      ) : (
        <BlackPlus className="mr-1 h-4" />
      )}
      <span className="ml-2 text-sm font-light">New Chat</span>
    </div>
  );
};

const ChatTitle = ({ theme, chat, RemoveUpload }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { editChatId, editTitle, setEditTitle, setEditChatId } = ChatStore();
  const [ifActive, setIfActive] = useState(false);

  const handleTitleChange = (event, uid) => {
    if (event.key === "Enter") {
      updateChatTitle(uid, event.target.value);
      setEditChatId(null);
    }
  };

  const handleBlur = (uid) => {
    // Reset to original title if "Enter" wasn't pressed
    if (editChatId === uid) {
      setEditTitle(chat.title);
      setEditChatId(null);
    }
  };

  const toggleEdit = (uid, title) => {
    setEditChatId(uid);
    setEditTitle(title);
    setActiveDropdown(null);
  };

  const handleDotClick = (uid) => {
    setActiveDropdown((prev) => (prev === uid ? null : uid));
  };

  return (
    <li className="w-full nav-item hide-scrollbar relative">
      <div
        className={`w-full flex justify-between items-center rounded-md`}
        onMouseEnter={(e) =>
          (e.currentTarget.querySelector(".menu-button").style.display = "flex")
        }
        onMouseLeave={(e) => {
          e.currentTarget.querySelector(".menu-button").style.display = "none";
          setActiveDropdown(null);
        }}
      >
        {editChatId === chat.uid ? (
          <input
            type="text"
            className={`rounded px-2 py-1 w-full ${
              theme === "dark"
                ? "bg-[#252530] text-white"
                : "bg-white text-black"
            }`}
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            onKeyDown={(e) => handleTitleChange(e, chat.uid)}
            onBlur={() => handleBlur(chat.uid)} // Reset title on blur
          />
        ) : (
          <NavLink
            to={`/chat/${chat.uid}`}
            onClick={RemoveUpload}
            className={({ isActive }) => {
              setIfActive(isActive);
              return `w-full text-sm flex items-center flex-1 px-4 py-2 h-[38px] rounded-md no-underline ${
                isActive
                  ? theme === "dark"
                    ? "text-white bg-[#3A3E4C]"
                    : "text-black bg-[#FEEAE6]"
                  : theme === "dark"
                  ? "text-gray-300 hover:bg-[#3A3E4C] hover:opacity-40"
                  : "text-gray-700 hover:bg-[#FEEAE6] hover:opacity-80"
              }`;
            }}
          >
            {chat.title.length > 22
              ? `${chat.title.substring(0, 22)}...`
              : chat.title}
          </NavLink>
        )}

        <div className="menu-container flex items-center absolute right-1">
          <button
            className="menu-button hidden items-center justify-center w-6 h-6 rounded-full hover:bg-[#FFEAE6] dark:bg-[#3A3E4C] dark:text-white"
            onClick={() => handleDotClick(chat.uid)}
          >
            {theme === "dark" ? (
              <Dot className="w-4 h-4 text-gray-500" />
            ) : (
              <BlackDot className="w-4 h-4 text-gray-800" />
            )}
          </button>

          {activeDropdown === chat.uid && (
            <div className="absolute right-0 top-full mt-1 bg-white dark:bg-[#40404D] dark:text-white rounded-lg shadow-lg py-1 min-w-[120px] z-50">
              <button
                onClick={() => toggleEdit(chat.uid, chat.title)}
                className="w-full flex items-center px-3 py-2 text-sm text-gray-700 dark:text-white"
              >
                <FiEdit className="w-4 h-4 mr-2" />
                Rename
              </button>
              {!ifActive && (
                <button
                  onClick={() => deleteChat(chat.uid)}
                  className="w-full flex items-center px-3 py-2 text-sm text-gray-700 dark:text-white"
                >
                  <RiDeleteBin6Line className="w-4 h-4 mr-2" />
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </li>
  );
};


const SidebarChatList = ({ theme, RemoveUpload }) => {
  const { sideChat } = ChatStore();

  return (
    <div className="overflow-y-scroll side-chat-scroll max-h-[calc(100vh-280px)] min-h-[calc(100vh-240px)]">
      <ul className="flex flex-col space-y-2 mt-2">
        {sideChat &&
          sideChat.map((chat, index) => (
            <ChatTitle
              key={chat.uid}
              chat={chat}
              theme={theme}
              RemoveUpload={RemoveUpload}
            />
          ))}
      </ul>
    </div>
  );
};

const Sidebarrr = () => {
  const sideRef = useRef(null);
  const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const { showSidebar, setShowSidebar } = UIStore();
  const { setInputFileName, setFileSize, setFileUrl, setShowUpload } =
    FileUploadStore();

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const RemoveUpload = () => {
    setInputFileName("");
    setFileSize("");
    setFileUrl("");
    setShowUpload(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth > 1024);
    };
  
    // Set initial state on component mount
    handleResize();
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setShowSidebar]);
  

  return (
    <div className="p-3 xxs:p-0 xs:p-0 xm:py-2">
      <SidebarToggler1
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
        theme={theme}
      />

      {showSidebar && (
        <div
          ref={sideRef}
          className={`xm:mx-1 sidebar transition-opacity duration-250 z-[1000] h-full rounded-[15px] w-[17.56vw] min-w-[240px] relative flex flex-col flex-shrink-0 px-2 ${
            theme === "dark"
              ? "bg-[#30303D] border-dotted border-black"
              : "bg-white border-gray-300"
          }`}
        >
          {/* Header */}
          <SideBarHeader
            theme={theme}
            toggleSidebar={toggleSidebar}
            toggleTheme={toggleTheme}
            isSettingsDropdownOpen={isSettingsDropdownOpen}
            setIsSettingsDropdownOpen={setIsSettingsDropdownOpen}
          />
          <hr
            className={`mt-2 ${
              theme === "dark" ? "border-gray-700" : "border-gray-300"
            }`}
          />

          {/* New Chat Button */}
          {/* to check the add chat disabled function */}
          <NewChatButton theme={theme} RemoveUpload={RemoveUpload} />
          <hr
            className={`mt-3 ${
              theme === "dark" ? "border-gray-700" : "border-gray-300"
            }`}
          />
          {/* Recent Chats Section */}
          <div className="mt-3 text-left">
            <span
              className={`text-sm font-semibold ${
                theme === "dark" ? "text-gray-100" : "text-gray-700"
              }`}
            >
              Recent
            </span>
          </div>
          {/* Chat List */}
          <SidebarChatList theme={theme} RemoveUpload={RemoveUpload} />

          {/* gradient for the overflow */}
          <div
            className={
              theme === "dark"
                ? "bg-gradient-to-b from-[#30303D00] via-[#30303D69] to-[#30303D] bg-no-repeat bg-origin-padding h-28 w-60 absolute bottom-0 left-0 rounded-b-lg z-0"
                : "bg-gradient-to-b from-[#FFFFFF00] via-[#FFFFFF] to-[#FFFFFF] bg-no-repeat bg-origin-padding h-28 w-60 absolute bottom-0 left-0 rounded-b-lg z-0"
            }
          ></div>
        </div>
      )}
    </div>
  );
};

export default Sidebarrr;
