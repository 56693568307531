let config = {};

export const initializeConfig = (secrets) => {
  config = {
    domain: secrets["DOMAIN"],
    redirectUri: secrets["REDIRECT_URI"],
    clientId: secrets["CLIENT_ID"],
    clientSecret: secrets["CLIENT_SECRET"],
    orgId: secrets["ORG_ID"],
    apiUrl: secrets["API_URL"],
    socketUrl: secrets["SOCKET_URL"],
    tokenEndpoint: "https://oauth2.googleapis.com/token",
  };
};

export const getConfig = () => config;
export const secretKey = process.env.REACT_APP_SECRET_KEY
export const apiUrl = process.env.REACT_APP_API_URL
