import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "highlight.js/styles/monokai-sublime.css";
import UserAuthStore from "../../../store/userStore";
import PromptStore from "../../../store/promptStore";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import EnvStore from "../../../store/secretStore";
import { apiUrl } from "../../../constants/const";
import UIStore from "../../../store/uiStore";
import { WelcomeSection } from "../Contents/WelcomeSection";
import UploadSection from "../Contents/UploadSection";
import OneMessage from "./SingleMessage";
import "../../../assets/css/styles.css";
import { useTheme } from "../../../context/ThemeProvider";
import ChatStore from "../../../store/chatStore";
import getMessages from "../../../utils/firestoreFunctions/getMessages";
import Prompt from "../Contents/Prompt";
import addMessages from "../../../utils/firestoreFunctions/addMessages";
import { FileStore } from "../../../store/fileUploadStore";

const ChatMessages = ({ chat_id }) => {
  const messageContainerRef = useRef(null);
  const { theme } = useTheme();
  const { user, orgId } = UserAuthStore();
  const { modeRef } = UIStore.getState();
  const [messages, setMessages] = useState([]);
  const [isLoadingMessages, setLoadingMessages] = useState(false);
  const [isMessagesRendered, setMessagesRendered] = useState(false);
  const { updateChatTitle, setAddChatDisabled } = ChatStore();
  const {files} = FileStore();

  const regenerate = async (id) => {
    try {
      let message_data = null;
      for (const message of messages) {
        if (message.id === id) {
          message_data = message;
          console.log(message_data);
        }
      }
      setMessages((prevMessages) =>
        prevMessages.map((message) => {
          if (message.id === id) {
            // Update the content for the matched message
            return {
              ...message,
              answers: [
                ...message.answers,
                {
                  content: "",
                  timestamp: {
                    seconds: 1736245799,
                    nanoseconds: 84000000,
                  },
                  source: "None",
                  active: true,
                  role: "model",
                },
              ],
              currentIndex: message.answers.length,
            };
          }
          return message;
        })
      );

      const response = await fetch(apiUrl + "/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: modeRef,
          prompt: message_data.content,
          chat_uid: chat_id,
          file_url: message_data.source,
          org_id: orgId,
          uid: user?.uid,
          regenerate: 1,
          style: null,
          prompt_id: id,
          cache_id: null,
          model_id: "gemini-1.5-flash-001",
          recaching: false,
        }),
      });

      if (!response.body) {
        throw new Error("Readable stream not supported in response.");
      }
      if (messages.length === 0) {
        updateChatTitle(chat_id, prompt);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let answerCont = "";

      for (let done = false; !done; ) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          console.log(chunk);
          if (chunk === "</END>") {
            await addMessages(
              orgId,
              chat_id,
              prompt,
              answerCont,
              user?.uid,
              id,
              "None",
              files?.map((file) => file.downloadURL)
            );
          } else {
            answerCont += chunk;
            setMessages((prevMessages) =>
              prevMessages.map((message) => {
                if (message.id === id) {
                  // Update the content for the matched message
                  return {
                    ...message,
                    answers: message.answers.map((answer, index) => {
                      if (index === message.answers.length - 1) {
                        return {
                          ...answer,
                          content: answer.content + chunk,
                        };
                      }
                      return answer;
                    }),
                  };
                }
                return message;
              })
            );
          }
          //            scrollToBottom();
        }
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const scrollToBottom = (smooth = true) => {
    const scrollRef = document.querySelector("#chat-messages");
    if (scrollRef) {
      if (smooth)
        scrollRef.scrollTo({ top: scrollRef.scrollHeight, behavior: "smooth" });
      else
        scrollRef.scrollTo({
          top: scrollRef.scrollHeight,
          behavior: "instant",
        });
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (user?.uid) {
        setLoadingMessages(true);
        const out = await getMessages(chat_id);
        // console.log(out);
        setMessages(out);
        // const messages = await loadChatMessages(chat_id);
        // setMessages(messages);
        setLoadingMessages(false);
        scrollToBottom();
      }
    };
    fetchMessages();
  }, [chat_id, user?.uid, orgId]);

  useLayoutEffect(() => {
    scrollToBottom(false);
    setMessagesRendered(true);
  }, [messages.length]);

  if (isLoadingMessages || !user?.uid) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {(!isLoadingMessages && messages.length === 0) || !chat_id ? (
        <>
          <WelcomeSection />
          <UploadSection chat_id={chat_id} />
        </>
      ) : (
        <div
          id="chat-messages"
          ref={messageContainerRef}
          className={`overflow-auto side-chat-scroll flex-grow h-[72vh] font-sans ml-4 ${
            isMessagesRendered ? "opacity-100" : "opacity-0"
          }`}
        >
          {messages?.map((chat, index) => (
            <OneMessage
              key={index}
              index={index}
              chat={chat}
              user={user}
              isLatest={index === messages.length - 1}
              theme={theme}
              regenFunc={regenerate}
            />
          ))}
        </div>
      )}

      <div className="">
        <Prompt
        chat_id={chat_id}
          send={async () => {
            const { prompt, setPrompt } = PromptStore.getState();
            setPrompt("");
            // setMessages((prev) => [...prev, { role: "user", content: prompt }]);
            setMessages((prev) => [
              ...prev,
              {
                role: "user",
                content: prompt,
                answers: [
                  {
                    content: "",
                    timestamp: {
                      seconds: 1736245799,
                      nanoseconds: 84000000,
                    },
                    source: "None",
                    active: true,
                    role: "model",
                  },
                ],
                currentIndex: 0,
                source: "None",
                id: null,
              },
            ]);
            const chatDocRef = doc(
              EnvStore.getState().db,
              "organisation",
              orgId,
              "users",
              user?.uid,
              "chats",
              chat_id
            );
            setAddChatDisabled(false);
            try {
              const response = await fetch(apiUrl + "/generate", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  type: modeRef,
                  prompt: prompt,
                  chat_uid: chat_id,
                  file_url: files?.map((file) => file.downloadURL),
                  org_id: orgId,
                  uid: user?.uid,
                  regenerate: null,
                  style: null,
                  prompt_id: null,
                  cache_id: null,
                  model_id: "gemini-1.5-flash-001",
                  recaching: false,
                }),
              });

              if (!response.body) {
                throw new Error("Readable stream not supported in response.");
              }
              // setMessages((prev) => [...prev, { role: "model", content: "" }]);
              if (messages.length === 0) {
                updateChatTitle(chat_id, prompt);
              }

              const reader = response.body.getReader();
              const decoder = new TextDecoder("utf-8");
              let answerCont = "";

              for (let done = false; !done; ) {
                const { value, done: readerDone } = await reader.read();
                done = readerDone;
                if (value) {
                  const chunk = decoder.decode(value, { stream: true });
                  console.log(chunk);
                  if (chunk === "</END>") {
                    const promptId = await addMessages(
                      orgId,
                      chat_id,
                      prompt,
                      answerCont,
                      user?.uid,
                      null,
                      "None",
                      files?.map((file) => file.downloadURL)
                    );
                    setMessages((prevMessages) => {
                      const lastMessage = prevMessages[prevMessages.length - 1];
                      lastMessage.id = promptId;
                      lastMessage.source = files.map((file) => file.downloadURL);
                      // console.log("previous", prevMessages);
                      return [...prevMessages];
                    });
                  } else {
                    answerCont += chunk;
                    setMessages((prevMessages) => {
                      const lastMessage = prevMessages[prevMessages.length - 1];
                      lastMessage.answers[
                        lastMessage?.currentIndex ?? 0
                      ].content += chunk;
                      return [...prevMessages];
                    });
                  }
                  scrollToBottom();
                }
              }
            } catch (error) {
              console.error("Error fetching messages:", error);
            }

            await updateDoc(chatDocRef, { updated_at: serverTimestamp() });
          }}
        />
      </div>
    </div>
  );
};

export default ChatMessages;
